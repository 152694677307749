import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ReportableEventField, ReportableEventFields } from '@/modules/common/models/reports.model'

@Component({
  name: 'GtrReportFieldSelect'
})
export default class GtrReportFieldSelect extends Vue {
  @Prop({ required: true, type: Object, default: {} })
  reportableEventFields!: ReportableEventFields;

  @Prop({ type: Array, default: [] })
  selectedFields!: ReportableEventField[];

  data () {
    return {
      loading: true,
      submitting: false,
      fieldSelectionModalOpen: false,
      fieldSearchFilter: '',
      selectedFieldsEdit: [] as ReportableEventField[],
      checkedFields: [] as ReportableEventField[]
    }
  }

  mounted () {
    // Populate selectedFieldsEdit with selected fields from reportableEventFields so they are the same objects as those used in the checklist (for comparison)
    if (Array.isArray(this.selectedFields)) {
      const sections = Object.keys(this.reportableEventFields)
      this.selectedFields.forEach(selectedField => {
        let found = false
        for (let s = 0; s < sections.length; s++) {
          const sectionFields = this.reportableEventFields[sections[s]]
          for (let f = 0; f < sectionFields.length; f++) {
            if (sectionFields[f].field === selectedField.field && sectionFields[f].type === selectedField.type) {
              this.$data.selectedFieldsEdit.push(sectionFields[f])
              found = true
              break
            }
          }
          if (found) {
            break
          }
        }
      })
    }
  }

  openFieldSelectionModal () {
    this.$data.checkedFields = this.$data.selectedFieldsEdit.slice()
    this.$data.fieldSelectionModalOpen = true
  }

  get visible () {
    const visibleSections = [] as string[]
    const visibleFields = [] as ReportableEventField[]
    const searchTerm = (this.$data.fieldSearchFilter ?? '').toLowerCase()

    for (const section in this.reportableEventFields) {
      if (section === 'custom') continue // don't show custom inputs.
      if (this.reportableEventFields.hasOwnProperty(section)) {
        const visibleSectionFields = this.reportableEventFields[section].filter((field: ReportableEventField) => {
          return field.label.toLowerCase().includes(searchTerm) || field.field.toLowerCase().includes(searchTerm)
        })
        if (visibleSectionFields.length > 0) {
          visibleSections.push(section)
          visibleFields.push(...visibleSectionFields)
        }
      }
    }
    return { sections: visibleSections, fields: visibleFields }
  }

  get allVisibleFieldsChecked (): boolean {
    let allVisibleFieldsChecked = true
    for (let i = 0; i < this.visible.fields.length; i++) {
      if (!this.$data.checkedFields.includes(this.visible.fields[i])) {
        allVisibleFieldsChecked = false
        break
      }
    }
    return allVisibleFieldsChecked
  }

  get readonly (): boolean {
    return true
  }

  /**
   * Select all visible fields if not all of them are selected; otherwise deselect all visible fields.
   */
  private selectAllChange () {
    if (this.allVisibleFieldsChecked) {
      this.$data.checkedFields = this.$data.checkedFields.filter((field: ReportableEventField) => !this.visible.fields.includes(field))
    } else {
      const missingFields = this.visible.fields.filter((field: ReportableEventField) => !this.$data.checkedFields.includes(field))
      this.$data.checkedFields.push(...missingFields)
    }
  }

  /**
   * Saves selected fields to export fields while preserving sort order.
   */
  saveFieldSelection () {
    const checkedFields = this.$data.checkedFields
    const selectedFieldsEdit = this.$data.selectedFieldsEdit

    // remove deselected fields
    for (let i = selectedFieldsEdit.length; i >= 0; i--) {
      if (!checkedFields.includes(selectedFieldsEdit[i])) {
        selectedFieldsEdit.splice(i, 1)
      }
    }

    // add newly selected fields
    for (let i = 0; i < checkedFields.length; i++) {
      if (!selectedFieldsEdit.includes(checkedFields[i])) {
        selectedFieldsEdit.push(checkedFields[i])
      }
    }

    this.$emit('update-field-selection', selectedFieldsEdit)
    this.closeFieldSelectionModal()
  }

  closeFieldSelectionModal () {
    this.$emit('close')
    this.$data.fieldSelectionModalOpen = false
    this.$data.fieldSearchFilter = ''
    this.$data.checkedFields.length = 0
  }

  updateSortOrder () {
    this.$emit('update-field-selection', this.$data.selectedFieldsEdit)
  }

  deselectField (index: number) {
    this.$data.selectedFieldsEdit.splice(index, 1)
    this.$emit('update-field-selection', this.$data.selectedFieldsEdit)
  }
}
